<template>
  <v-container fluid class="admin-view">
    <v-row dense justify="center">
      <v-col cols="auto" class="text-left" align-self="center">
        <v-btn small dark color="black" @click="getBookings">
          <span class="d-none d-sm-block pr-1">Refresh</span>
          <v-icon x-small>mdi-sync</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="text-right text-lg-left text-md-left text-sm-left"
        align-self="center"
      >
        <export-excel
          :data="computedTableData"
          name="Bookings.xls"
          :fields="excelHeaders"
        >
          <v-btn small dark color="black">
            <span class="d-none d-sm-block pr-1">Export To Excel</span>
            <v-icon x-small>mdi-download</v-icon>
          </v-btn>
        </export-excel>
      </v-col>
      <v-col
        cols="auto"
        class="text-right text-lg-left text-md-left text-sm-left"
        align-self="center"
      >
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="12" lg="2" md="2" sm="8">
        <v-text-field
          color="secondary"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        lg="2"
        md="2"
        sm="4"
        class="text-left"
        align-self="center"
      >
        <v-select
          v-model="filterByStatusId"
          :items="[
            { id: 1, description: 'Pending' },
            { id: 2, description: 'Accepted' },
          ]"
          item-value="id"
          item-text="description"
          label="Filter By Status"
          placeholder="Filter By Status"
          color="black"
          dense
          clearable
          hide-details="true"
          outlined
          hide-selected
          :menu-props="{ offsetY: true }"
        ></v-select>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-menu
          v-model="filterMenuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFilterDateFromFormatted"
              label="From Date"
              placeholder="From Date"
              append-icon="mdi-calendar"
              readonly
              color="black"
              dense
              hide-details="true"
              outlined
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="clearFilterDateFrom"
            ></v-text-field>
          </template>
          <v-date-picker
            elevation="2"
            v-model="filterDateFrom"
            color="green lighten-1"
            header-color="black"
            @input="filterMenuFrom = false"
            @change="getBookings"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="6">
        <v-menu
          v-model="filterMenuTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFilterDateToFormatted"
              label="To Date"
              placeholder="To Date"
              append-icon="mdi-calendar"
              readonly
              color="black"
              dense
              hide-details="true"
              outlined
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="clearFilterDateTo"
            ></v-text-field>
          </template>
          <v-date-picker
            elevation="2"
            v-model="filterDateTo"
            color="green lighten-1"
            header-color="black"
            @input="filterMenuTo = false"
            @change="getBookings"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" class="mt-3">
        <v-data-table
          :headers="headers"
          :items="computedTableData"
          :items-per-page="5"
          light
          class="elevation-1 black--text lightpink"
          :search="search"
          ref="dataTable"
          :expanded="expanded"
        >
          <template v-slot:[`item.tabledecor`]="{ item }">
            <div>
              {{ item.tabledecor === 1 ? "Yes" : "No" }}
            </div>
          </template>
          <template v-slot:[`item.balloondecor`]="{ item }">
            <div>
              {{ item.balloondecor === 1 ? "Yes" : "No" }}
            </div>
          </template>
          <template v-slot:[`item.rosebouquet`]="{ item }">
            <div>
              {{ item.rosebouquet === 1 ? "Yes" : "No" }}
            </div>
          </template>
          <template v-slot:[`item.photoprop`]="{ item }">
            <div>
              {{ item.photoprop === 1 ? "Yes" : "No" }}
            </div>
          </template>
          <template v-slot:[`item.gloosyphoto`]="{ item }">
            <div>
              {{ item.gloosyphoto === 1 ? "Yes" : "No" }}
            </div>
          </template>
          <template v-slot:[`item.foggyrosepetalpath`]="{ item }">
            <div>
              {{ item.foggyrosepetalpath === 1 ? "Yes" : "No" }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- Pending -->
            <div class="d-flex" v-if="item.statusid === 1">
              <v-btn icon color="success" @click="actionClick(item, 1)">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon color="error" @click="actionClick(item, 2)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <!-- Accepted -->
            <div v-else-if="item.statusid === 2">
              <v-btn icon color="secondary" @click="edit(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon color="error" @click="actionClick(item, 2)">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </div>
            <!-- Rejected -->
            <!-- <div v-else>Rejected</div> -->
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div style="width: calc(100vw - 80px)">
                <BookSlot
                  :key="item.id"
                  mode="update"
                  :id="item.id"
                  @updateBooking="getBookings"
                ></BookSlot>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="acceptDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h6"> Accept the Booking </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="selectedRow.amount"
                label="Total Amount"
                placeholder="Total Amount"
                color="black"
                dense
                hide-details="true"
                outlined
                type="number"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedRow.advanceamount"
                label="Amount Paid"
                placeholder="Amount Paid"
                color="black"
                dense
                hide-details="true"
                outlined
                type="number"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="amountdue"
                label="Amount Due"
                placeholder="Amount Due"
                color="black"
                dense
                hide-details="true"
                outlined
                type="number"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="black"
                @click="acceptDialog = false"
                dark
                outlined
                small
                class="mr-2"
              >
                cancel
              </v-btn>
              <v-btn color="black" @click="acceptBooking" dark small>
                save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h6 justify-center"
          >Are you sure you want to reject this booking!
        </v-card-title>
        <v-card-text>
          <v-btn dark color="black" outlined @click="close" class="mr-2" small>
            No
          </v-btn>
          <v-btn dark color="black" small @click="rejectBooking"> Yes </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import BookSlot from "@/components/BookSlot.vue";
import {
  getAllBookings,
  getBookings,
  getBookingsBetween,
  bookSlot,
  getCakeDD,
  getSpecialDecorDD,
  getComboDD,
  getOccasionDD,
  viewAllplans,
  acceptBooking,
  rejectBooking,
} from "@/services/admin/booking.service";
import {
  viewSlots,
  viewAvailableSlots,
} from "@/services/booking/booking.service";
import { mapGetters } from "vuex";

export default {
  nam: "BookingPage",
  data() {
    return {
      expanded: [],
      selectedRow: "",
      formData: {
        username: "usofficial22@gmail.com",
        password: "Ullasshalini@22",
      },
      tableData: [],
      search: "",
      filterByStatusId: "",
      excelHeaders: {
        Id: "id",
        Status: "statusdescription",
        "Booking Reference": "bookingreference",
        Plan: "plan",
        Date: "date",
        Slot: "slotdescription",
        "Booking Name": "bookingname",
        "Total Amount": "amount",
        "Amount Paid": "advanceamount",
        "Amount Due": "amountdue",
        Email: "email",
        Phone: "phone",
        cake: "cake",
        occasion: "occasion",
        "Special Person Name": "specialpersonname",
        "No of Guests": "people",
        Message: "message",
        CRM: "crm",
        "Booking Date": "createts",
      },
      menu: false,
      filterMenuFrom: false,
      filterMenuTo: false,
      filterDateFrom: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      filterDateTo: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      rules: {
        required: (value) => !!value || "Required.",
      },
      acceptDialog: false,
      rejectDialog: false,
      specialDecor: [],
      combos: [],
    };
  },
  created() {
    this.getSpecialDecorDD();
    this.getComboDD();
  },
  watch: {
    getSelectedLocation: {
      handler: function (location) {
        if (location) this.getBookings();
      },
      immediate: true,
    },
  },
  components: { BookSlot },
  computed: {
    ...mapGetters(["getSelectedLocation"]),
    computedDateFormatted() {
      return this.formatDate(this.bookingDetails.date);
    },
    computedFilterDateFromFormatted: {
      get() {
        if (this.filterDateFrom) return this.formatDate(this.filterDateFrom);
        else return "";
      },
      set(date) {
        return date;
      },
    },
    computedFilterDateToFormatted: {
      get() {
        if (this.filterDateTo) return this.formatDate(this.filterDateTo);
        else return "";
      },
      set(date) {
        return date;
      },
    },
    amountdue: {
      get() {
        return this.selectedRow.amount - this.selectedRow.advanceamount;
      },
      set(amount) {
        return amount;
      },
    },
    computedTableData() {
      // if (this.computedFilterDateFromFormatted && this.filterByStatusId) {
      //   return this.tableData.filter(
      //     (data) =>
      //       data.date >= this.computedFilterDateFromFormatted &&
      //       data.statusid === this.filterByStatusId
      //   );
      // } else if (
      //   this.computedFilterDateFromFormatted &&
      //   this.computedFilterDateToFormatted
      // ) {
      //   return this.tableData.filter(
      //     (data) =>
      //       data.date >= this.computedFilterDateFromFormatted &&
      //       data.date <= this.computedFilterDateToFormatted
      //   );
      // } else if (this.computedFilterDateFromFormatted) {
      //   return this.tableData.filter(
      //     (data) => data.date >= this.computedFilterDateFromFormatted
      //   );
      // } else if (this.computedFilterDateToFormatted) {
      //   return this.tableData.filter(
      //     (data) => data.date <= this.computedFilterDateToFormatted
      //   );
      // } else if (this.filterByStatusId) {
      //   return this.tableData.filter(
      //     (data) => data.statusid === this.filterByStatusId
      //   );
      // }
      // return this.tableData;
      const filteredData = this.tableData.filter((data) => {
        if (
          this.computedFilterDateFromFormatted &&
          this.computedFilterDateToFormatted &&
          this.filterByStatusId
        ) {
          return (
            this.changeDateFormate(data.date) >=
              this.changeDateFormate(this.computedFilterDateFromFormatted) &&
            this.changeDateFormate(data.date) <=
              this.changeDateFormate(this.computedFilterDateToFormatted) &&
            data.statusid === this.filterByStatusId
          );
        }

        if (
          this.computedFilterDateFromFormatted &&
          this.computedFilterDateToFormatted
        ) {
          return (
            this.changeDateFormate(data.date) >=
              this.changeDateFormate(this.computedFilterDateFromFormatted) &&
            this.changeDateFormate(data.date) <=
              this.changeDateFormate(this.computedFilterDateToFormatted)
          );
        }
        if (this.computedFilterDateFromFormatted) {
          return data.date >= this.computedFilterDateFromFormatted;
        }
        if (this.computedFilterDateToFormatted) {
          return data.date <= this.computedFilterDateToFormatted;
        }

        if (this.filterByStatusId) {
          return data.statusid === this.filterByStatusId;
        }
        return true; // No filters applied, include all records
      });

      return filteredData;
    },
    headers() {
      return [
        { text: "Actions", value: "actions", sortable: false },
        { text: "Status", value: "statusdescription", sortable: false },
        { text: "Booking Reference", value: "bookingreference" },
        { text: "Plan", value: "plan" },
        { text: "Date", value: "date" },
        { text: "Slot", value: "slotdescription" },
        { text: "Booking Name", value: "bookingname" },
        { text: "Total Amount", value: "amount" },
        { text: "Amount Paid", value: "advanceamount" },
        { text: "Amount Due", value: "amountdue" },
        { text: "Phone", value: "phone" },
        { text: "Special Decor", value: "specialdecor" },
        { text: "Combos", value: "combos" },
        { text: "Cake", value: "cake" },
        { text: "Occasion", value: "occasion" },
        { text: "Special Person Name", value: "specialpersonname" },
        { text: "No of Guests", value: "people" },
        { text: "Message", value: "message" },
        { text: "CRM", value: "crm" },
        { text: "Booking Date", value: "createts" },
      ];
    },
  },
  methods: {
    clearFilterDateFrom() {
      this.filterDateFrom = "";
      this.getBookings();
    },
    clearFilterDateTo() {
      this.filterDateTo = "";
      this.getBookings();
    },

    changeDateFormate(date) {
      const parts = date.split("/"); // Split the date string by "/"
      return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`)
        .toISOString()
        .slice(0, 10);
    },
    edit(item) {
      const index = this.expanded.findIndex((el) => el.id === item.id);

      if (index !== -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(item);
      }
    },
    actionClick(item, type) {
      this.selectedRow = item;
      if (type === 1) {
        this.acceptDialog = true;
      } else if (type === 2) {
        this.rejectDialog = true;
      }
      this.expanded = [];
    },
    close() {
      this.acceptDialog = false;
      this.rejectDialog = false;
    },
    acceptBooking() {
      this.selectedRow.amountdue = this.amountdue;
      acceptBooking(this.selectedRow)
        .then((response) => {
          if (response.data.success) {
            this.close();
            this.getBookings();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    rejectBooking() {
      rejectBooking(this.selectedRow)
        .then((response) => {
          if (response.data.success) {
            this.close();
            this.getBookings();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    book() {
      if (this.$refs.adminBooking.validate()) {
        bookSlot(this.bookingDetails)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message);
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    async getBookings() {
      try {
        let response;
        if (
          this.computedFilterDateFromFormatted &&
          this.computedFilterDateToFormatted
        ) {
          response = await getBookingsBetween(
            this.changeDateFormate(this.computedFilterDateFromFormatted),
            this.changeDateFormate(this.computedFilterDateToFormatted)
          );
        } else if (this.computedFilterDateFromFormatted) {
          response = await getBookings(
            this.changeDateFormate(this.computedFilterDateFromFormatted)
          );
        } else {
          response = await getAllBookings();
        }

        const specialDecorNames = this.specialDecor.reduce((map, decor) => {
          map[decor.id] = decor.name;
          return map;
        }, {});

        const comboNames = this.combos.reduce((map, combo) => {
          map[combo.id] = combo.name;
          return map;
        }, {});

        this.tableData = response.data.data.map((data) => {
          const combosSelectedIds = data.combosselected
            ? JSON.parse(data.combosselected)
            : [];
          const specialDecorSelectedIds = data.specialdecorselected
            ? JSON.parse(data.specialdecorselected)
            : [];

          return {
            ...data,
            specialdecor: specialDecorSelectedIds
              .map((id) => specialDecorNames[id])
              .sort()
              .join(" | "),
            combos: combosSelectedIds
              .map((id) => comboNames[id])
              .sort()
              .join(" | "),
            cake:
              data.cake !== null && data.cake !== "No"
                ? data.cake.replace(/\s\(\d+\)$/, "") +
                  " | " +
                  data.nameonthecake
                : "",
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    viewSlots() {
      viewSlots(this.bookingDetails.planid)
        .then((response) => {
          this.slots = response.data.data;
          this.viewAvailableSlots();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    viewAvailableSlots() {
      viewAvailableSlots({
        date: this.bookingDetails.date,
        planId: this.bookingDetails.planid,
      })
        .then((response) => {
          this.availableSlots = response.data.data;

          if (
            new Date(this.bookingDetails.date).getDate() -
              new Date().getDate() ==
            0
          ) {
            this.availableSlots = this.availableSlots.filter((slot) => {
              if (
                this.convertTimeFormat12to24(slot.description.split("-")[0]) >
                new Date().getHours()
              ) {
                return slot;
              }
            });
          }
          this.bookingDetails.slotid = this.availableSlots[0]?.id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    convertTimeFormat12to24(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      // var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1].trim();
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      // var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      // if (minutes < 10) sMinutes = "0" + sMinutes;

      // return sHours + ":" + sMinutes;
      return sHours;
    },
    viewAllplans() {
      viewAllplans().then((response) => {
        this.availablePlans = response.data.data;
      });
    },
    getCakeDD() {
      getCakeDD().then((response) => {
        this.cake = response.data.data;
      });
    },
    getSpecialDecorDD() {
      getSpecialDecorDD().then((response) => {
        this.specialDecor = response.data.data;
      });
    },
    getComboDD() {
      getComboDD().then((response) => {
        this.combos = response.data.data;
      });
    },
    getOccasionDD() {
      getOccasionDD().then((response) => {
        this.occasion = response.data.data;
      });
    },
  },
};
</script>
<style>
.admin-view th,
.admin-view td {
  white-space: nowrap;
}
.admin-view .card-width {
  width: min(500px, 100%);
}
</style>
