<template>
  <v-card
    :flat="mode === 'update'"
    color="lightpink"
    class="pa-6 pa-lg-10 pa-md-10 my-4 mx-lg-4 mx-md-4"
  >
    <v-form ref="adminBooking" @submit.prevent="book">
      <v-row justify="center">
        <v-col cols="12" lg="8" md="8">
          <v-row no-gutters v-if="mode === 'add'">
            <v-col cols="12" lg="auto" md="auto" class="align-self-center">
              <h3 class="mr-3 text-left">
                Book slots in {{ getSelectedLocationName }}
              </h3>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" lg="4" md="4" sm="6">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="bookingDetails.bookingname"
                    label="Booking Name*"
                    placeholder="Booking Name*"
                    color="black"
                    dense
                    hide-details="true"
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateFormatted"
                        label="Date*"
                        placeholder="Date*"
                        append-icon="mdi-calendar"
                        readonly
                        color="black"
                        dense
                        hide-details="true"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :disabled="mode === 'update'"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      elevation="2"
                      v-model="bookingDetails.date"
                      color="green lighten-1"
                      header-color="black"
                      @input="menu = false"
                      @change="viewAvailableSlots()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="bookingDetails.planid"
                    :items="availablePlans"
                    item-value="id"
                    item-text="title"
                    label="Plan*"
                    placeholder="Plan*"
                    color="black"
                    dense
                    hide-details="true"
                    outlined
                    hide-selected
                    :menu-props="{ offsetY: true }"
                    @change="viewAvailableSlots()"
                    :disabled="mode === 'update'"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="bookingDetails.slotid"
                    :items="availableSlots"
                    item-value="id"
                    item-text="description"
                    label="Slot*"
                    placeholder="Slot*"
                    color="black"
                    dense
                    hide-details="true"
                    outlined
                    hide-selected
                    :menu-props="{ offsetY: true }"
                    :disabled="mode === 'update'"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="bookingDetails.occasionid"
                    :items="occasion"
                    item-value="id"
                    item-text="description"
                    label="Occasion"
                    placeholder="Occasion"
                    color="black"
                    dense
                    hide-details="true"
                    outlined
                    hide-selected
                    :menu-props="{ offsetY: true }"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="bookingDetails.cakeid"
                    :items="cakes"
                    item-value="id"
                    item-text="description"
                    label="Cake"
                    placeholder="Cake"
                    color="black"
                    dense
                    hide-details="true"
                    outlined
                    hide-selected
                    :menu-props="{ offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    color="black"
                    placeholder="Name of the cake"
                    label="Name of the cake"
                    v-model="bookingDetails.nameonthecake"
                    outlined
                    :hide-details="true"
                    dense
                    autoComplete="nope"
                    :disabled="!bookingDetails.cakeid"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    color="black"
                    placeholder="Special Person Name"
                    label="Special Person Name"
                    v-model="bookingDetails.specialpersonname"
                    outlined
                    hide-details="auto"
                    dense
                    autoComplete="nope"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="bookingDetails.email"
                    label="Email"
                    placeholder="Email"
                    color="black"
                    dense
                    hide-details="true"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="bookingDetails.phone"
                    label="Phone*"
                    placeholder="Phone*"
                    color="black"
                    dense
                    hide-details="true"
                    outlined
                    type="number"
                    hide-spin-buttons
                    :rules="[rules.required, rules.phone]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="bookingDetails.people"
                    label="No of Guests*"
                    placeholder="No of Guests*"
                    color="black"
                    dense
                    type="number"
                    :min="this.peopleAllowed()"
                    hide-details="true"
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="bookingDetails.crm"
                    label="CRM*"
                    placeholder="CRM*"
                    color="black"
                    :items="crmData"
                    item-value="id"
                    item-text="name"
                    dense
                    hide-details="true"
                    hide-selected
                    outlined
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="6">
              <v-row>
                <v-col
                  cols="12"
                  lg="12"
                  md="12"
                  order="3"
                  order-lg="1"
                  order-md="1"
                >
                  <v-textarea
                    v-model="bookingDetails.message"
                    label="Message"
                    placeholder="Message"
                    color="black"
                    hide-details="auto"
                    outlined
                    :rows="4"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="12" md="12">
              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  sm="6"
                  v-for="combo in combos"
                  :key="`c-${combo.id}`"
                >
                  <v-checkbox
                    v-model="bookingDetails.combosselected"
                    color="black"
                    class="ma-0"
                    :label="combo.name + ' - ' + combo.amount"
                    :value="combo.id"
                    :hide-details="true"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  lg="4"
                  md="4"
                  sm="6"
                  v-for="decor in specialDecor"
                  :key="`d-${decor.id}`"
                >
                  <v-checkbox
                    v-model="bookingDetails.specialdecorselected"
                    color="black"
                    class="ma-0"
                    :label="decor.name + ' - ' + decor.amount"
                    :value="decor.id"
                    :hide-details="true"
                  ></v-checkbox>
                  <v-text-field
                    v-if="
                      decor.id === 7 &&
                      bookingDetails.specialdecorselected.includes(7)
                    "
                    class="mt-4"
                    color="black"
                    type="number"
                    placeholder="ex: 18"
                    label="Please specify the Numbers"
                    v-model="bookingDetails.numbers"
                    outlined
                    hide-details="auto"
                    hide-spin-buttons
                    dense
                    autofocus
                    autoComplete="nope"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4" md="4">
          <v-card class="py-6 fill-height" color="primary">
            <v-row dense justify="center">
              <v-col cols="11">
                <v-row justify="space-between" v-if="getPlanData">
                  <v-col cols="auto">
                    <b>
                      {{ getPlanData.title }} ({{ this.peopleAllowed() }}):
                    </b>
                  </v-col>
                  <v-col cols="auto">
                    <b>
                      Rs.
                      {{
                        this.isThreeHourSlot
                          ? getPlanData.amount + getPlanData.maxdiscount
                          : getPlanData.amount
                      }}.00 /-
                    </b>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col cols="auto">
                    <b>
                      Extra Members ({{
                        bookingDetails.people - this.peopleAllowed()
                      }}):
                    </b>
                  </v-col>
                  <v-col cols="auto">
                    <b>
                      Rs.
                      {{
                        (bookingDetails.people - this.peopleAllowed()) * 100
                      }}.00 /-
                    </b>
                  </v-col>
                </v-row>
                <v-row
                  justify="space-between"
                  v-if="getCakeData && bookingDetails.cakeid !== 1"
                >
                  <v-col cols="auto">
                    <v-icon x-small @click="clearSelectedCake">
                      mdi-delete
                    </v-icon>
                    <b> {{ getCakeData.description }}:</b>
                  </v-col>
                  <v-col cols="auto">
                    <b> Rs. {{ getCakeData.amount }}.00 /- </b>
                  </v-col>
                </v-row>
                <template v-if="bookingDetails.combosselected.length">
                  <v-row
                    justify="space-between"
                    v-for="combo in getComboData"
                    :key="`combo-${combo.id}`"
                  >
                    <v-col cols="auto">
                      <b> {{ combo.name }}: </b>
                    </v-col>
                    <v-col cols="auto">
                      <b> Rs. {{ combo.amount }}.00 /- </b>
                    </v-col>
                  </v-row>
                </template>

                <template v-if="bookingDetails.specialdecorselected.length">
                  <v-row
                    justify="space-between"
                    v-for="decor in getSpecialDecorData"
                    :key="`decor-${decor.id}`"
                  >
                    <v-col cols="auto">
                      <b> {{ decor.name }}: </b>
                    </v-col>
                    <v-col cols="auto">
                      <b> Rs. {{ decor.amount }}.00 /- </b>
                    </v-col>
                  </v-row>
                </template>

                <div class="divider"></div>
                <v-row justify="space-between">
                  <v-col cols="auto">
                    <b>Sub Total: </b>
                  </v-col>
                  <v-col cols="auto">
                    <b> Rs. {{ totalPayable }}.00 /- </b>
                  </v-col>
                </v-row>
                <div class="divider"></div>

                <v-row>
                  <v-col cols="12" lg="7" md="7" sm="6" class="text-left">
                    <b>Food Bill </b>
                    <v-text-field
                      class="lightpink"
                      v-model.number="bookingDetails.foodamount"
                      placeholder="Food Bill"
                      color="black"
                      dense
                      outlined
                      hide-details="true"
                      type="number"
                      min="0"
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="5" md="5" sm="6" class="text-left">
                    <b>Others</b>

                    <v-text-field
                      class="lightpink"
                      v-model.number="bookingDetails.otheramount"
                      placeholder="Others"
                      color="black"
                      dense
                      outlined
                      hide-details="true"
                      type="number"
                      min="0"
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="7" md="7" sm="6" class="text-left">
                    <b>Cleaning Charge </b>

                    <v-text-field
                      class="lightpink"
                      v-model.number="bookingDetails.cleaningcharge"
                      placeholder="Cleaning Charge"
                      color="black"
                      dense
                      outlined
                      hide-details="true"
                      type="number"
                      min="0"
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="5" md="5" sm="6" class="text-left">
                    <b>Discount </b>

                    <v-text-field
                      class="lightpink"
                      v-model.number="bookingDetails.discount"
                      placeholder="Discount"
                      color="black"
                      dense
                      outlined
                      hide-details="true"
                      type="number"
                      min="0"
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-left">
                    <b>Advance Paid </b>

                    <v-text-field
                      class="lightpink mb-3"
                      v-model.number="bookingDetails.advanceamount"
                      placeholder="Advance Amount"
                      color="black"
                      dense
                      outlined
                      type="number"
                      min="0"
                      hide-details
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                </v-row>

                <div class="divider"></div>

                <v-row no-gutters justify="space-between">
                  <v-col cols="auto" align-self="center">
                    <b>Amount Due: </b>
                  </v-col>
                  <v-col cols="auto">
                    <div>
                      <b>Rs. {{ amountDue }}.00 /-</b>
                    </div>
                  </v-col>
                </v-row>

                <div class="divider"></div>

                <v-btn
                  dark
                  color="black"
                  @click="updateBooking"
                  v-if="mode === 'update'"
                  class="mt-3"
                  >Update
                </v-btn>
                <v-btn dark color="black" type="submit" v-else class="mt-3"
                  >Book Slot
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  bookSlot,
  getCakeDD,
  getOccasionDD,
  getSpecialDecorDD,
  getComboDD,
  viewAllplans,
  getBookingById,
  updateBooking,
} from "@/services/admin/booking.service";
import {
  viewSlots,
  viewAvailableSlots,
} from "@/services/booking/booking.service";

export default {
  nam: "BookSlot",
  data() {
    return {
      selectedRow: "",
      tableData: [],
      crmData: [
        { id: 1, name: "ullas" },
        { id: 2, name: "shalini" },
        { id: 3, name: "ranjitha" },
        { id: 4, name: "keerthana" },
        { id: 5, name: "bjoomika" },
        { id: 6, name: "yogesh" },
        { id: 7, name: "ravi" },
        { id: 8, name: "nandiraj" },
        { id: 9, name: "ajay" },
        { id: 10, name: "ningu" },
        { id: 11, name: "abhishek" },
        { id: 12, name: "jyothi" },
        { id: 13, name: "kavya" },
        { id: 14, name: "dinesh" },
        { id: 15, name: "puneeth" },
        { id: 16, name: "ajayb" },
        { id: 17, name: "balu" },
        { id: 18, name: "amit" },
        { id: 19, name: "roopa" },
        { id: 20, name: "pavithra" },
        { id: 21, name: "santosh" },
        { id: 22, name: "Rajesh" },
        { id: 23, name: "Jyothi" },
        { id: 24, name: "Prashanth" },
        { id: 25, name: "Pavithra" },
      ],
      bookingDetails: {
        bookingname: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        planid: 1,
        slotid: "",
        cakeid: 1,
        occasionid: "",
        specialpersonname: "",
        nameonthecake: "",
        email: "",
        phone: "",
        people: this.peopleAllowed(),
        totalpayable: 0,
        foodamount: 0,
        cleaningcharge: 0,
        otheramount: 0,
        discount: 0,
        amountdue: 0,
        advanceamount: 0,
        message: "",
        crm: "",
        cakesselected: [],
        specialdecorselected: [],
        combosselected: [],
        locationid: "",
        numbers: "",
      },
      slots: [],
      availableSlots: [],
      availablePlans: [],

      cakes: [],
      specialDecor: [],
      combos: [],
      occasion: [],
      menu: false,
      filterDate: "",
      rules: {
        required: (value) => !!value || "Required.",
        phone: (value) => value.length === 10 || "Max 10 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  props: {
    mode: {
      default: "add",
    },
    id: null,
  },
  mounted() {
    if (this.mode === "update") {
      this.getBookingById();
    } else {
      this.viewSlots();
      this.getCakeDD();
      this.getSpecialDecorDD();
      this.getComboDD();
      this.getOccasionDD();
    }
  },
  watch: {
    "bookingDetails.cakeid"(val) {
      if (val === 1) this.bookingDetails.nameonthecake = "";
    },
    getSelectedLocation: {
      handler: function (location) {
        if (location) this.viewAllplans();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getSelectedLocationName", "getSelectedLocation"]),

    isThreeHourSlot() {
      return (
        (this.bookingDetails.slotid &&
          this.availableSlots.find(
            (slot) => slot.id === this.bookingDetails.slotid
          )?.isthreehourslot) ||
        0
      );
    },

    messageValidationRules() {
      // Check if the message is not empty, then apply the validation rule
      return this.bookingDetails.message.trim() !== ""
        ? [
            (v) =>
              /^[\w\s]+$/.test(v) ||
              "Message must only contain alphabets, numeric and space",
          ]
        : [];
    },
    getSpecialDecorData() {
      return this.specialDecor.filter((decor) =>
        this.bookingDetails.specialdecorselected.includes(decor.id)
      );
    },
    getComboData() {
      return this.combos.filter((combo) =>
        this.bookingDetails.combosselected.includes(combo.id)
      );
    },
    getPlanData() {
      return this.availablePlans.find(
        (plan) => plan.id === this.bookingDetails.planid
      );
    },
    getCakeData() {
      return this.cakes.find((cake) => cake.id === this.bookingDetails.cakeid);
    },
    amountDue() {
      return (
        this.totalPayable +
        (parseInt(this.bookingDetails.foodamount)
          ? parseInt(this.bookingDetails.foodamount)
          : 0) +
        (parseInt(this.bookingDetails.otheramount)
          ? parseInt(this.bookingDetails.otheramount)
          : 0) +
        (parseInt(this.bookingDetails.cleaningcharge)
          ? parseInt(this.bookingDetails.cleaningcharge)
          : 0) -
        (parseInt(this.bookingDetails.discount)
          ? parseInt(this.bookingDetails.discount)
          : 0) -
        (parseInt(this.bookingDetails.advanceamount)
          ? parseInt(this.bookingDetails.advanceamount)
          : 0)
      );
    },
    totalPayable() {
      let total = 0;

      total += this.getPlanData
        ? parseInt(
            this.isThreeHourSlot
              ? this.getPlanData.amount + this.getPlanData.maxdiscount
              : this.getPlanData.amount
          )
        : 0;

      total += this.getCakeData ? parseInt(this.getCakeData.amount) : 0;

      // if (this.bookingDetails.cakeid === 1) total += 50;

      if (this.bookingDetails.people > this.peopleAllowed())
        total += (this.bookingDetails.people - this.peopleAllowed()) * 100;

      this.getSpecialDecorData.forEach((decor) => {
        total += parseInt(decor.amount);
      });

      this.getComboData.forEach((combo) => {
        total += parseInt(combo.amount);
      });

      return total;
    },
    computedDateFormatted() {
      return this.formatDate(this.bookingDetails.date);
    },
    amountdue: {
      get() {
        return this.selectedRow.amount - this.selectedRow.advanceamount;
      },
      set(amount) {
        return amount;
      },
    },
    amount: {
      get() {
        return 10;
      },
      set(amount) {
        return amount;
      },
    },
  },
  methods: {
    peopleAllowed() {
      return this.getPlanData ? this.getPlanData.people : 2;
    },
    clearSelectedCake() {
      this.bookingDetails.cakeid = 1;
      this.bookingDetails.nameonthecake = "";
    },
    getBookingById() {
      getBookingById({ id: this.id })
        .then(async (response) => {
          if (response.data.success) {
            this.bookingDetails = response.data.results;

            this.bookingDetails.specialdecorselected = this.bookingDetails
              .specialdecorselected
              ? JSON.parse(this.bookingDetails.specialdecorselected)
              : [];
            this.bookingDetails.combosselected = this.bookingDetails
              .combosselected
              ? JSON.parse(this.bookingDetails.combosselected)
              : [];

            this.bookingDetails.cakeid = this.bookingDetails.cakeid
              ? this.bookingDetails.cakeid
              : 1;

            await this.viewAllplans();
            await this.viewSlots();
            await this.getCakeDD();
            await this.getSpecialDecorDD();
            await this.getComboDD();
            await this.getOccasionDD();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    updateBooking() {
      if (this.$refs.adminBooking.validate()) {
        this.bookingDetails.amountdue = this.amountDue;
        this.bookingDetails.totalpayable = this.totalPayable;
        updateBooking(this.bookingDetails)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message);
              this.$emit("updateBooking");
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    book() {
      if (this.$refs.adminBooking.validate()) {
        this.bookingDetails.locationid = this.getSelectedLocation;
        this.bookingDetails.amountdue = this.amountDue;
        this.bookingDetails.totalpayable = this.totalPayable;
        bookSlot(this.bookingDetails)
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message);
            } else {
              this.$toast.error(response.data.message);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    viewSlots() {
      viewSlots(this.bookingDetails.planid)
        .then((response) => {
          this.slots = response.data.data;
          if (this.mode === "update") {
            this.availableSlots = response.data.data;
          } else {
            this.viewAvailableSlots();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    viewAvailableSlots() {
      viewAvailableSlots({
        date: this.bookingDetails.date,
        planId: this.bookingDetails.planid,
      })
        .then((response) => {
          this.availableSlots = response.data.data;
          // if (
          //   new Date(this.bookingDetails.date).getDate() -
          //     new Date().getDate() ==
          //   0
          // ) {
          // this.availableSlots = this.availableSlots.filter((slot) => {
          //   if (
          //     this.convertTimeFormat12to24(slot.description.split("-")[0]) >
          //     new Date().getHours()
          //   ) {
          //     return slot;
          //   }
          // });
          // }
          this.bookingDetails.slotid = this.availableSlots[0]?.id;
          this.bookingDetails.people = this.peopleAllowed();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    convertTimeFormat12to24(time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      // var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1].trim();
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      // var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      // if (minutes < 10) sMinutes = "0" + sMinutes;

      // return sHours + ":" + sMinutes;
      return sHours;
    },
    viewAllplans() {
      viewAllplans().then((response) => {
        this.availablePlans = response.data.data;

        this.bookingDetails.planid =
          this.mode === "add"
            ? this.availablePlans[0]?.id
            : this.bookingDetails.planid;
      });
    },
    getCakeDD() {
      getCakeDD().then((response) => {
        this.cakes = response.data.data;
      });
    },
    getSpecialDecorDD() {
      getSpecialDecorDD().then((response) => {
        this.specialDecor = response.data.data;
      });
    },
    getComboDD() {
      getComboDD().then((response) => {
        this.combos = response.data.data;
      });
    },
    getOccasionDD() {
      getOccasionDD().then((response) => {
        this.occasion = response.data.data;
      });
    },
  },
};
</script>
<style scoped>
.divider {
  width: 100%;
  border: 1px dashed black;
  margin: 15px 0;
}
</style>
