<template>
  <v-container fluid class="admin-view">
    <v-row dense justify="center" class="text-left">
      <v-col cols="auto" class="text-left" align-self="center">
        <v-btn small dark color="black" @click="viewTimeSheet">
          <span class="d-none d-sm-block pr-1">Refresh</span>
          <v-icon x-small>mdi-sync</v-icon>
        </v-btn>
      </v-col>

      <v-spacer></v-spacer>

      <v-col cols="12" lg="3" md="3">
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFilterDateFormatted"
              label="Filter By Date"
              placeholder="Filter By Date*"
              append-icon="mdi-calendar"
              readonly
              color="black"
              dense
              hide-details="true"
              outlined
              v-bind="attrs"
              v-on="on"
              @click:clear="filterDate = ''"
            ></v-text-field>
          </template>
          <v-date-picker
            elevation="2"
            v-model="filterDate"
            color="green lighten-1"
            header-color="black"
            @input="(filterMenu = false), viewTimeSheet()"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12">
        <div class="mt-6">
          <b> Rooftop Bookings</b>
          <v-btn
            small
            dark
            color="black"
            @click="exportRooftopExcel"
            outlined
            class="ml-4"
          >
            <span class="d-none d-sm-block pr-1">
              Generate Rooftop Timesheet
            </span>
            <v-icon x-small>mdi-download</v-icon>
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" class="mt-3 text-left">
        <div :style="{ width: '96vw', overflow: 'auto' }">
          <table ref="rooftop" :style="{ borderCollapse: 'collapse' }">
            <tr>
              <th
                class="pa-2"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                Time Sheet
              </th>
              <th
                class="pa-2"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                {{ displayDate(filterDate) + " Bookings" }}
              </th>
              <th
                class="pa-2"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                Food
              </th>
              <th
                class="pa-2"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                Sign
              </th>
            </tr>
            <template v-for="(data, index) in rooftopSlots">
              <tr :key="'roof-top-head-' + index">
                <th class="pa-2" :style="{ border: '1px solid gray' }"></th>
                <th
                  class="pa-2"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                >
                  {{ Object.keys(data)[0] }}
                </th>
                <th
                  class="pa-2"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                ></th>
                <th
                  class="pa-2"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                ></th>
              </tr>
              <tr
                :key="'roof-top-body-' + index + slotIndex"
                v-for="(slot, slotIndex) in Object.values(data)[0]"
              >
                <td class="pa-2" :style="{ border: '1px solid gray' }">
                  {{ slot.plan }}
                </td>
                <td class="pa-2" :style="{ border: '1px solid gray' }">
                  {{ rowDataRooftop(slot)?.details }}
                </td>
                <td class="pa-2" :style="{ border: '1px solid gray' }"></td>
                <td class="pa-2" :style="{ border: '1px solid gray' }"></td>
              </tr>
            </template>
          </table>
        </div>
      </v-col>

      <v-col cols="12">
        <div class="mt-16">
          <b> Premium Bookings</b>
          <v-btn
            small
            dark
            color="black"
            @click="exportPremiumExcel"
            outlined
            class="ml-4"
          >
            <span class="d-none d-sm-block pr-1">
              Generate Premium Timesheet
            </span>
            <v-icon x-small>mdi-download</v-icon>
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" class="mt-3 text-left">
        <div :style="{ width: '96vw', overflow: 'auto' }">
          <table ref="premium" :style="{ borderCollapse: 'collapse' }">
            <tr>
              <th
                class="pa-2"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                Time Sheet
              </th>
              <th
                class="pa-2"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                {{ displayDate(filterDate) + " Bookings" }}
              </th>
              <th
                class="pa-2"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                Food
              </th>
              <th
                class="pa-2"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                Sign
              </th>
            </tr>
            <template v-for="(data, index) in premiumSlots">
              <tr :key="'premium-head-' + index">
                <th class="pa-2" :style="{ border: '1px solid gray' }"></th>
                <th
                  class="pa-2"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                >
                  {{ Object.keys(data)[0] }}
                </th>
                <th
                  class="pa-2"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                ></th>
                <th
                  class="pa-2"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                ></th>
              </tr>
              <tr
                :key="'premium-body-' + index + slotIndex"
                v-for="(slot, slotIndex) in Object.values(data)[0]"
              >
                <td class="pa-2" :style="{ border: '1px solid gray' }">
                  {{ slot.plan }}
                </td>
                <td class="pa-2" :style="{ border: '1px solid gray' }">
                  {{ rowDataPremium(slot)?.details }}
                </td>
                <td class="pa-2" :style="{ border: '1px solid gray' }"></td>
                <td class="pa-2" :style="{ border: '1px solid gray' }"></td>
              </tr>
            </template>
          </table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  getSpecialDecorDD,
  getComboDD,
  viewTimeSheet,
  viewAllSlots,
} from "@/services/admin/booking.service";

import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { mapGetters } from "vuex";

export default {
  nam: "ViewTimeSheet",
  data() {
    return {
      rooftopSlots: [],
      premiumSlots: [],
      tableDataRooftop: [],
      tableDataPremium: [],
      filterByStatusId: "",
      excelHeaders: {
        Plan: "plan",
        Slot: "slotdescription",
        Details: "details",
      },
      menu: false,
      filterMenu: false,
      filterDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      rules: {
        required: (value) => !!value || "Required.",
      },
      specialDecor: [],
      combos: [],
      selectedPlanType: 1,
    };
  },
  created() {
    this.getSpecialDecorDD();
    this.getComboDD();
  },
  watch: {
    getSelectedLocation: {
      handler: function (location) {
        if (location) {
          this.viewTimeSheet();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["getSelectedLocation"]),
    displayDate() {
      return (dateString) => {
        return moment(dateString).format("MMM Do");
      };
    },
    computedDateFormatted() {
      return this.formatDate(this.bookingDetails.date);
    },
    computedFilterDateFormatted: {
      get() {
        if (this.filterDate) return this.formatDate(this.filterDate);
        else return "";
      },
      set(date) {
        return date;
      },
    },
    headers() {
      return [
        { text: "Plan", value: "plan" },
        { text: "Slot", value: "slotdescription" },
        { text: "Details", value: "details" },
      ];
    },
  },
  methods: {
    rowDataRooftop(rowData) {
      return this.tableDataRooftop.find(
        (data) => data.slotid === rowData.slotid
      );
    },
    rowDataPremium(rowData) {
      return this.tableDataPremium.find(
        (data) => data.slotid === rowData.slotid
      );
    },
    cakeDataRooftop(rowData) {
      return this.tableDataRooftop.find(
        (data) => data.slotid === rowData.slotid
      );
    },
    cakeDataPremium(rowData) {
      return this.tableDataPremium.find(
        (data) => data.slotid === rowData.slotid
      );
    },
    viewAllSlots() {
      viewAllSlots(this.getSelectedLocation)
        .then((response) => {
          let rooftopSlots = {};
          let premiumSlots = {};

          response.data.data.forEach((slot) => {
            if (slot.plantype === 1) {
              if (
                Object.prototype.hasOwnProperty.call(
                  rooftopSlots,
                  slot.description
                )
              ) {
                rooftopSlots[slot.description].push(slot);
              } else {
                rooftopSlots[slot.description] = [slot];
              }
            }

            if (slot.plantype === 2) {
              if (
                Object.prototype.hasOwnProperty.call(
                  premiumSlots,
                  slot.description
                )
              ) {
                premiumSlots[slot.description].push(slot);
              } else {
                premiumSlots[slot.description] = [slot];
              }
            }
          });

          let rooftopArray = Object.keys(rooftopSlots).map((description) => {
            return {
              [description]: rooftopSlots[description],
            };
          });

          let premiumArray = Object.keys(premiumSlots).map((description) => {
            return {
              [description]: premiumSlots[description],
            };
          });

          this.rooftopSlots = rooftopArray;
          this.premiumSlots = premiumArray;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSpecialDecorDD() {
      getSpecialDecorDD().then((response) => {
        this.specialDecor = response.data.data;
      });
    },
    getComboDD() {
      getComboDD().then((response) => {
        this.combos = response.data.data;
      });
    },
    async viewTimeSheet() {
      try {
        const response = await viewTimeSheet({
          locationId: this.getSelectedLocation,
          date: this.filterDate,
        });

        const specialDecorNames = this.specialDecor.reduce((map, decor) => {
          map[decor.id] = decor.name;
          return map;
        }, {});

        const comboNames = this.combos.reduce((map, combo) => {
          map[combo.id] = combo.name;
          return map;
        }, {});

        this.tableDataRooftop = response.data.data
          .filter((item) => {
            // Assuming that the plan type is stored in the 'plantype' property of each item
            return item.plantype === 1;
          })
          .map((item) => {
            const combosSelectedIds = item.combosselected
              ? JSON.parse(item.combosselected)
              : [];
            const selectedCombosNames = combosSelectedIds
              .map((id) => comboNames[id])
              .sort();

            const specialDecorSelectedIds = item.specialdecorselected
              ? JSON.parse(item.specialdecorselected)
              : [];
            const selectedDecorNames = specialDecorSelectedIds
              .map((id) => {
                if (id === 7) {
                  return specialDecorNames[id] + " : " + item.numbers;
                }
                return specialDecorNames[id];
              })
              .sort();

            const specialdecor = selectedDecorNames.join(" | ");
            const combos = selectedCombosNames.join(" | ");

            const cake =
              item.cake !== null && item.cake !== "No"
                ? item.cake.replace(/\s\(\d+\)$/, "") +
                  " - " +
                  item.nameonthecake
                : "";

            const details = [
              item.bookingname,
              item.phone,
              combos,
              cake,
              specialdecor,
              item.nameonthecake,
              item.occasion + "-" + item.specialpersonname,
              item.people + "m",
              item.amountdue,
            ]
              .filter(Boolean)
              .join(" | ");

            return {
              ...item,
              details,
              cake,
              combos,
              specialdecor,
            };
          });
        this.tableDataPremium = response.data.data
          .filter((item) => {
            // Assuming that the plan type is stored in the 'plantype' property of each item
            return item.plantype === 2;
          })
          .map((item) => {
            const combosSelectedIds = item.combosselected
              ? JSON.parse(item.combosselected)
              : [];
            const selectedCombosNames = combosSelectedIds
              .map((id) => comboNames[id])
              .sort();

            const specialDecorSelectedIds = item.specialdecorselected
              ? JSON.parse(item.specialdecorselected)
              : [];
            const selectedDecorNames = specialDecorSelectedIds
              .map((id) => {
                if (id === 7) {
                  return specialDecorNames[id] + " : " + item.numbers;
                }
                return specialDecorNames[id];
              })
              .sort();

            if (item.numbers) console.log(item.numbers);

            const specialdecor = selectedDecorNames.join(" | ");
            const combos = selectedCombosNames.join(" | ");

            const cake =
              item.cake !== null && item.cake !== "No"
                ? item.cake.replace(/\s\(\d+\)$/, "") +
                  " - " +
                  item.nameonthecake
                : "";

            const details = [
              item.bookingname,
              item.phone,
              combos,
              cake,
              specialdecor,
              item.nameonthecake,
              item.occasion + "-" + item.specialpersonname,
              item.people + "m",
              item.amountdue,
            ]
              .filter(Boolean)
              .join(" | ");

            return {
              ...item,
              details,
              cake,
              combos,
              specialdecor,
            };
          });

        this.viewAllSlots();
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    exportRooftopExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.rooftop);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(
        workbook,
        this.displayDate(this.filterDate) + " Rooftop Bookings.xlsx"
      );
    },
    exportPremiumExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.premium);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(
        workbook,
        this.displayDate(this.filterDate) + " Premium Bookings.xlsx"
      );
    },
  },
};
</script>
<style>
.admin-view th,
.admin-view td {
  white-space: nowrap;
}
.admin-view .card-width {
  width: min(500px, 100%);
}
</style>
