import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import BookSlot from "../views/BookSlot.vue";
import BookingPage from "../views/BookingPage.vue";
import TimeSheet from "../views/TimeSheet.vue";
import CakeSheet from "../views/CakeSheet.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "Login", component: LoginPage },
  {
    path: "/book-slot",
    name: "BookSlot",
    component: BookSlot,
    meta: { requiresAuth: true },
  },
  {
    path: "/bookings",
    name: "Bookings",
    component: BookingPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/time-sheet",
    name: "TimeSheet",
    component: TimeSheet,
    meta: { requiresAuth: true },
  },
  {
    path: "/cake-sheet",
    name: "CakeSheet",
    component: CakeSheet,
    meta: { requiresAuth: true },
  },
];

const defaultRoute = "Bookings"; // Set the default route for redirection

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/",
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !token) {
    // Redirect to Login if trying to access an authenticated route without authentication
    next({ name: "Login" });
  } else if (!requiresAuth && token) {
    // Redirect to default route if trying to access a non-authenticated route while logged in
    next({ name: defaultRoute });
  } else if (!router.options.routes.find((route) => route.path === to.path)) {
    // Redirect to default route if the requested route is not defined
    next({ name: defaultRoute });
  } else {
    // Continue with navigation
    next();
  }
});

export default router;
