import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    dark: false,
    theme: {
        themes: {
            light: {
                header: "#FCF2DC",
                primary: "#FCF2DC",
                secondary: "#2D2D2F",
                lightgrey: "#F4EFE9",
                lightpink: "#F5EBE0",
                grey: "#343434",
                error: "#b22222",
                white: "#ffffff",
                black: "#151515",
                honeydew: "#f0fff0",
                orange: "#DD6031",
                brown1: "#a68053",
                brown: "#9B6D1E",
                lightbrown: "#EABE7C"
            }
        },
    },
})