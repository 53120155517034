<template>
  <v-container fluid>
    <v-row no-gutters justify="center">
      <v-col cols="12" align-self="center">
        <BookSlot></BookSlot>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import BookSlot from "@/components/BookSlot.vue";

export default {
  nam: "AdminView",
  data() {
    return {};
  },
  created() {},
  watch: {},
  components: { BookSlot },
  computed: {},
  methods: {},
};
</script>
