import axios from "axios";
import Vue from "vue";
import store from "@/store";
import { API_URL, Prod_API_URL } from "@/services/config/config";

function createApiBase() {
  if (process.env.NODE_ENV === "production") {
    return Prod_API_URL;
  } else {
    return API_URL;
  }
}

const ResourcesServer = axios.create({
  baseURL: createApiBase(),
});

Vue.prototype.$http = ResourcesServer;
const vm = new Vue();

ResourcesServer.interceptors.response.use(
  (response) => {
    store.commit("popRequestedEndPoints", response.config.url);
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  async (error) => {
    store.commit("popRequestedEndPoints", error.config.url);
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          vm.$toast.error(error.response.data.message);
          break;
        case 401:
          vm.$toast.error(error.response.data.message);
          break;
        case 403:
          vm.$toast.error(error.response.data.message);
          break;
        case 404:
          vm.$toast.error("Resource not found");
          break;
        case 409:
          vm.$toast.error("This Record already exists!");
          break;
        case 502:
          vm.$toast.error("There was an Error processing your Request");
          break;
        default:
          vm.$toast.error(
            error.response.data.message || "Internal server error"
          );
      }
      return Promise.reject(error.response);
    }
  }
);

ResourcesServer.interceptors.request.use(function (config) {
  store.commit("pushRequestedEndPoints", config.url);
  store.commit("setSpinner", true);
  const token = localStorage.getItem("token");

  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Content-Type"] = "application/json";
    config.headers["accept"] = "application/json";
    config.headers["applicationType"] = "web";
    config.headers["Accept-Language"] = "en";
    return config;
  } else {
    console.log("error: token not found");
    store.commit("setSpinner", false);
  }
});

export default ResourcesServer;
