<template>
  <v-container fluid class="admin-view">
    <v-row dense justify="center" class="text-left">
      <v-col cols="auto" class="text-left" align-self="center">
        <v-btn small dark color="black" @click="viewCakeSheet">
          <span class="d-none d-sm-block pr-1">Refresh</span>
          <v-icon x-small>mdi-sync</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-left" align-self="center">
        <v-btn
          small
          dark
          color="black"
          @click="exportCakeExcel"
          outlined
          class="ml-4"
        >
          <span class="d-none d-sm-block pr-1"> Generate Cakesheet </span>
          <v-icon x-small>mdi-download</v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>

      <v-col cols="12" lg="3" md="3">
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFilterDateFormatted"
              label="Filter By Date"
              placeholder="Filter By Date*"
              append-icon="mdi-calendar"
              readonly
              color="black"
              dense
              hide-details="true"
              outlined
              v-bind="attrs"
              v-on="on"
              @click:clear="filterDate = ''"
            ></v-text-field>
          </template>
          <v-date-picker
            elevation="2"
            v-model="filterDate"
            color="green lighten-1"
            header-color="black"
            @input="(filterMenu = false), viewCakeSheet()"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" class="mt-3 text-left">
        <div :style="{ width: '96vw', overflow: 'auto' }">
          <table ref="cakesheet" :style="{ borderCollapse: 'collapse' }">
            <tr>
              <th
                colspan="4"
                class="pa-2 text-center"
                :style="{
                  backgroundColor: '#EEECE1',
                  border: '1px solid gray',
                }"
              >
                {{ displayDate(filterDate) + " Cakes" }}
              </th>
            </tr>
            <template>
              <tr key="head9AM">
                <th
                  colspan="4"
                  class="pa-2 text-center"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                >
                  9:00 AM
                </th>
              </tr>
              <tr :key="'body9AM' + data.slotid" v-for="data in slotsBefore2PM">
                <template v-if="cakeData(data)?.cake">
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ data.plan }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ cakeData(data)?.bookingname }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ cakeData(data)?.cake }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ data.plantype === 2 ? "Premium" : "Terrace" }}
                  </td>
                </template>
              </tr>
            </template>
            <template>
              <tr key="head2PM">
                <th
                  colspan="4"
                  class="pa-2 text-center"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                >
                  2:00 PM
                </th>
              </tr>
              <tr :key="'body2PM' + data.slotid" v-for="data in slotsBefore5PM">
                <template v-if="cakeData(data)?.cake">
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ data.plan }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ cakeData(data)?.bookingname }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ cakeData(data)?.cake }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ data.plantype === 2 ? "Premium" : "Terrace" }}
                  </td>
                </template>
              </tr>
            </template>
            <template>
              <tr key="head5PM">
                <th
                  colspan="4"
                  class="pa-2 text-center"
                  :style="{
                    backgroundColor: '#EEECE1',
                    border: '1px solid gray',
                  }"
                >
                  5:00 PM
                </th>
              </tr>
              <tr :key="'body5PM' + data.slotid" v-for="data in slotsAfter5PM">
                <template v-if="cakeData(data)?.cake">
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ data.plan }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ cakeData(data)?.bookingname }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ cakeData(data)?.cake }}
                  </td>
                  <td class="pa-2" :style="{ border: '1px solid gray' }">
                    {{ data.plantype === 2 ? "Premium" : "Terrace" }}
                  </td>
                </template>
              </tr>
            </template>
          </table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  getSpecialDecorDD,
  getComboDD,
  viewCakeSheet,
  viewAllSlots,
} from "@/services/admin/booking.service";

import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { mapGetters } from "vuex";

export default {
  nam: "viewCakeSheet",
  data() {
    return {
      cakes: [],
      slotsBefore2PM: [],
      slotsBefore5PM: [],
      slotsAfter5PM: [],
      excelHeaders: {
        Plan: "plan",
        Slot: "slotdescription",
        Details: "details",
      },
      menu: false,
      filterMenu: false,
      filterDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      rules: {
        required: (value) => !!value || "Required.",
      },
      specialDecor: [],
      combos: [],
      selectedPlanType: 1,
    };
  },
  created() {
    this.getSpecialDecorDD();
    this.getComboDD();
  },
  watch: {
    getSelectedLocation: {
      handler: function (location) {
        if (location) {
          this.viewCakeSheet();
          this.viewAllSlots();
        }
      },
      immediate: true,
    },
  },
  components: {},
  computed: {
    ...mapGetters(["getSelectedLocation"]),
    displayDate() {
      return (dateString) => {
        return moment(dateString).format("MMM Do");
      };
    },
    computedDateFormatted() {
      return this.formatDate(this.bookingDetails.date);
    },
    computedFilterDateFormatted: {
      get() {
        if (this.filterDate) return this.formatDate(this.filterDate);
        else return "";
      },
      set(date) {
        return date;
      },
    },
    headers() {
      return [
        { text: "Plan", value: "plan" },
        { text: "Slot", value: "slotdescription" },
        { text: "Details", value: "details" },
      ];
    },
  },
  methods: {
    cakeData(rowData) {
      return this.cakes.find((data) => data.slotid === rowData.slotid);
    },
    viewAllSlots() {
      viewAllSlots(this.getSelectedLocation)
        .then((response) => {
          // this.slotsBefore4PM = response.data.data
          //   .filter((cake) => {
          //     return (
          //       this.parseSlotDescription(
          //         cake.description
          //       ).startDate.getHours() < 16
          //     );
          //   })
          //   .sort((a, b) => {
          //     if (a.plantype < b.plantype) {
          //       return 1;
          //     } else if (a.plantype > b.plantype) {
          //       return -1;
          //     }
          //   });

          // this.slotsAfter4PM = response.data.data
          //   .filter((cake) => {
          //     return (
          //       this.parseSlotDescription(
          //         cake.description
          //       ).startDate.getHours() >= 16
          //     );
          //   })
          //   .sort((a, b) => {
          //     if (a.plantype < b.plantype) {
          //       return 1;
          //     } else if (a.plantype > b.plantype) {
          //       return -1;
          //     }
          //   });
          this.slotsBefore2PM = response.data.data
            .filter((cake) => {
              return (
                this.parseSlotDescription(
                  cake.description
                ).startDate.getHours() < 14
              );
            })
            .sort((a, b) => {
              if (a.plantype < b.plantype) {
                return 1;
              } else if (a.plantype > b.plantype) {
                return -1;
              }
            });

          this.slotsBefore5PM = response.data.data
            .filter((cake) => {
              return (
                this.parseSlotDescription(
                  cake.description
                ).startDate.getHours() >= 14 &&
                this.parseSlotDescription(
                  cake.description
                ).startDate.getHours() < 17
              );
            })
            .sort((a, b) => {
              if (a.plantype < b.plantype) {
                return 1;
              } else if (a.plantype > b.plantype) {
                return -1;
              }
            });

          this.slotsAfter5PM = response.data.data
            .filter((cake) => {
              return (
                this.parseSlotDescription(
                  cake.description
                ).startDate.getHours() >= 17
              );
            })
            .sort((a, b) => {
              if (a.plantype < b.plantype) {
                return 1;
              } else if (a.plantype > b.plantype) {
                return -1;
              }
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getSpecialDecorDD() {
      getSpecialDecorDD().then((response) => {
        this.specialDecor = response.data.data;
      });
    },
    getComboDD() {
      getComboDD().then((response) => {
        this.combos = response.data.data;
      });
    },
    // Function to convert slotdescription to Date object
    parseSlotDescription(slotdescription) {
      const [startTime, endTime] = slotdescription.split(" - ");
      const [startHour, startMinute, startPeriod] = startTime.split(/:| /);
      const [endHour, endMinute, endPeriod] = endTime.split(/:| /);

      const startDate = new Date();
      startDate.setHours(
        parseInt(startHour, 10) + (startPeriod === "PM" ? 12 : 0)
      );
      startDate.setMinutes(parseInt(startMinute, 10));

      const endDate = new Date();
      endDate.setHours(parseInt(endHour, 10) + (endPeriod === "PM" ? 12 : 0));
      endDate.setMinutes(parseInt(endMinute, 10));

      return { startDate, endDate };
    },
    async viewCakeSheet() {
      try {
        const response = await viewCakeSheet({
          locationId: this.getSelectedLocation,
          date: this.filterDate,
        });

        const specialDecorNames = this.specialDecor.reduce((map, decor) => {
          map[decor.id] = decor.name;
          return map;
        }, {});

        const comboNames = this.combos.reduce((map, combo) => {
          map[combo.id] = combo.name;
          return map;
        }, {});

        this.cakes = response.data.data.map((item) => {
          const combosSelectedIds = item.combosselected
            ? JSON.parse(item.combosselected)
            : [];
          const selectedCombosNames = combosSelectedIds
            .map((id) => comboNames[id])
            .sort();

          const specialDecorSelectedIds = item.specialdecorselected
            ? JSON.parse(item.specialdecorselected)
            : [];
          const selectedDecorNames = specialDecorSelectedIds
            .map((id) => specialDecorNames[id])
            .sort();

          const specialdecor = selectedDecorNames.join(" | ");
          const combos = selectedCombosNames.join(" | ");

          const cake =
            item.cake !== null && item.cake !== "No"
              ? item.cake.replace(/\s\(\d+\)$/, "") + " - " + item.nameonthecake
              : "";

          const details = [
            item.bookingname,
            item.phone,
            combos,
            cake,
            specialdecor,
            item.nameonthecake,
            item.occasion + "-" + item.specialpersonname,
            item.people + "m",
            item.amountdue,
          ]
            .filter(Boolean)
            .join(" | ");

          return {
            ...item,
            details,
            cake,
            combos,
            specialdecor,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    exportCakeExcel() {
      const worksheet = XLSX.utils.table_to_sheet(this.$refs.cakesheet);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(
        workbook,
        this.displayDate(this.filterDate) + " Cake Sheet.xlsx"
      );
    },
  },
};
</script>
<style>
.admin-view th,
.admin-view td {
  white-space: nowrap;
}
.admin-view .card-width {
  width: min(500px, 100%);
}
</style>
