<template>
  <v-container fluid>
    <v-card color="transparent" flat>
      <v-row>
        <v-col cols="12">
          <div>
            Copyright ©2023 All Rights Reserved | Garden of dreams Bangalore
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "AppFooter",
  components: {},
  methods: {},
};
</script>
