import Vue from "vue";
import Vuex from "vuex";
import { jwtDecode } from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showSpinner: false,
    isMobile: false,
    requestedEndPoints: [],
    userData: null,
    isLoggedIn: false,
    locations: [],
    selectedLocation: "",
  },
  getters: {
    getUserData(state) {
      return state.userData;
    },
    getSpinner(state) {
      return state.showSpinner;
    },
    isMobile(state) {
      return state.isMobile;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    getLocations(state) {
      return state.locations;
    },
    getSelectedLocation(state) {
      return state.selectedLocation;
    },
    getSelectedLocationName(state) {
      return state.locations.find(
        (location) => location.id === state.selectedLocation
      )?.name;
    },
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },
    setMobile(state, value) {
      state.isMobile = value;
    },
    setSpinner(state, value) {
      state.showSpinner = value;
    },
    pushRequestedEndPoints(state, payload) {
      state.requestedEndPoints.push(payload);
    },
    popRequestedEndPoints(state, payload) {
      state.requestedEndPoints = state.requestedEndPoints.filter(
        (e) => e !== payload
      );
      if (state.requestedEndPoints.length === 0) {
        state.showSpinner = false;
      }
    },
    setIsLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    restoreAuthentication(state) {
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        state.userData = decodedToken;
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
    },
    setLocations(state, locations) {
      state.locations = locations;
    },
    setSelectedLocation(state, location) {
      state.selectedLocation = location;
    },
  },
  actions: {
    setMobile({ commit }, value) {
      commit("setMobile", value);
    },
    setSpinner({ commit }, value) {
      commit("setSpinner", value);
    },
    pushRequestedEndPoints({ commit }, value) {
      commit("pushRequestedEndPoints", value);
    },
    setLocations({ commit }, value) {
      commit("setLocations", value);
    },
    setSelectedLocation({ commit }, value) {
      commit("setSelectedLocation", value);
    },
  },
  modules: {},
});
