import resourcesServer from "@/services/config/resourcesServerConfig";

export const viewSlots = (planId) => {
  return resourcesServer.get(`/booking/view-slots/${planId}`);
};
export const viewAvailableSlots = (data) => {
  return resourcesServer.post(`/booking/view-available-slots`, data);
};
export const makePayment = (data) => {
  return resourcesServer.post(`/booking/book-now`, data);
};
export const verifySignature = (data) => {
  return resourcesServer.post(`/booking/verify-signature`, data);
};
export const getAllBookings = () => {
  return resourcesServer.get("/booking/get-all-bookings");
};
export const sendMailConfirmation = (data) => {
  return resourcesServer.post("/booking/send-mail-confirmation", data);
};
export const getBookingByReferenceId = (data) => {
  return resourcesServer.post("/booking/get-booking-by-reference-id", data);
};
