import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import excel from "vue-excel-export";

const options = {
  transition: "Vue-Toastification__fade",
  maxToasts: 5,
  newestOnTop: true,
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  },
};
Vue.use(Toast, options);
Vue.use(excel);

Vue.config.productionTip = false;

// Call the restoreAuthentication mutation to check for a valid token on page refresh
store.commit('restoreAuthentication');

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
