import store from "@/store";
import resourcesServer from "@/services/config/resourcesServerConfig";

export const bookSlot = (data) => {
  return resourcesServer.post("/admin/booking/book-now-admin", data);
};

export const getAllBookings = () => {
  return resourcesServer.get(
    `/admin/booking/get-all-bookings/${store.getters.getSelectedLocation}`
  );
};

export const getBookings = (date) => {
  return resourcesServer.get(
    `/admin/booking/get-bookings/${store.getters.getSelectedLocation}/${date}`
  );
};

export const getBookingsBetween = (fromDate, toDate) => {
  return resourcesServer.get(
    `/admin/booking/get-bookings-between/${store.getters.getSelectedLocation}/${fromDate}/${toDate}`
  );
};

export const viewTimeSheet = (data) => {
  return resourcesServer.post("/admin/booking/view-time-sheet", data);
};

export const viewCakeSheet = (data) => {
  return resourcesServer.post("/admin/booking/view-time-sheet", data);
};

export const viewAllSlots = (locationId) => {
  return resourcesServer.get(`/admin/booking/view-all-slots/${locationId}`);
};

export const getCakeDD = () => {
  return resourcesServer.get("/admin/booking/get-cake-dd");
};

export const getSpecialDecorDD = () => {
  return resourcesServer.get("/admin/booking/get-special-decor-dd");
};

export const getOccasionDD = () => {
  return resourcesServer.get("/admin/booking/get-occasion-dd");
};

export const getComboDD = () => {
  return resourcesServer.get("/admin/booking/get-combo-dd");
};

export const viewAllplans = () => {
  return resourcesServer.get(`/admin/booking/view-all-plans/${store.getters.getSelectedLocation}`);
};

export const getBookingById = (data) => {
  return resourcesServer.post("/admin/booking/get-booking-by-id", data);
};

export const acceptBooking = (data) => {
  return resourcesServer.post("/admin/booking/admin-accept-booking", data);
};

export const rejectBooking = (data) => {
  return resourcesServer.post("/admin/booking/admin-reject-booking", data);
};

export const updateBooking = (data) => {
  return resourcesServer.post(`/admin/booking/update-booking`, data);
};
export const getAllLocationsByUserId = () => {
  return resourcesServer.get(`/admin/booking/get-all-locations-by-user-id`);
};
